import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import './AuthPages.css';
import axios from 'axios';
import googleIcon from '../google-icon.png';

const LoginPage = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { login, playAsGuest, loginWithGoogle } = useAuth();
  const navigate = useNavigate();
  const [showResendButton, setShowResendButton] = useState(false);
  const [verificationEmail, setVerificationEmail] = useState('');
  const [resendLoading, setResendLoading] = useState(false);
  const [resendSuccess, setResendSuccess] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (formRef.current) {
        formRef.current.classList.add('active');
      }
    }, 300);
    
    return () => clearTimeout(timer);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!usernameOrEmail || !password) {
      setError('Please fill in all fields');
      return;
    }
    
    try {
      setError('');
      setLoading(true);
      setResendSuccess(false);
      const result = await login(usernameOrEmail, password);
      
      if (result.success) {
        navigate('/');
      } else if (result.requiresVerification) {
        setError(`${result.message} A verification link was sent to ${result.email}`);
        setShowResendButton(true);
        setVerificationEmail(result.email);
      } else {
        setError(result.message || 'Invalid username/email or password');
        setShowResendButton(false);
      }
    } catch (err) {
      setError('An error occurred during login');
      setShowResendButton(false);
    } finally {
      setLoading(false);
    }
  };

  const handlePlayAsGuest = (e) => {
    e.preventDefault();
    playAsGuest();
    navigate('/');
  };

  const handleResendVerification = async () => {
    try {
      setResendLoading(true);
      const response = await axios.post('/api/auth/resend-verification', { email: verificationEmail });
      setResendSuccess(true);
      setError('');
    } catch (err) {
      setError('Failed to resend verification email. Please try again later.');
      setResendSuccess(false);
    } finally {
      setResendLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      setError('');
      const result = await loginWithGoogle();
      if (result.success) {
        navigate('/');
      } else {
        setError(result.message || 'Google login failed');
      }
    } catch (err) {
      setError('An error occurred during Google login');
    }
  };

  return (
    <div className="auth-page">
      <div className="auth-container">
        <h1>Login</h1>
        {error && <div className="auth-error">{error}</div>}
        
        <form ref={formRef} onSubmit={handleSubmit} className="auth-form form">
          <div className="form-group">
            <label htmlFor="usernameOrEmail">Username or Email</label>
            <input
              type="text"
              id="usernameOrEmail"
              value={usernameOrEmail}
              onChange={(e) => setUsernameOrEmail(e.target.value)}
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="form-control"
            />
          </div>
          <div className="forgot-password-link">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
          <button 
            type="submit" 
            className="auth-button" 
            disabled={loading}
          >
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>
        
        <div className="auth-divider">OR</div>
        
        <button 
          onClick={handleGoogleLogin} 
          className="google-button"
        >
          <img src={googleIcon} alt="Google" className="google-icon" />
          Continue with Google
        </button>
        
        <div className="auth-divider">OR</div>
        
        <button 
          onClick={handlePlayAsGuest} 
          className="guest-button"
        >
          Play as Guest
        </button>
        
        {showResendButton && (
          <div className="resend-verification">
            <p>Didn't receive the email?</p>
            {resendSuccess ? (
              <div className="resend-success">Email Sent!</div>
            ) : (
              <button 
                onClick={handleResendVerification}
                disabled={resendLoading}
                className="auth-button resend-button"
              >
                {resendLoading ? 'Sending...' : 'Resend Verification Email'}
              </button>
            )}
          </div>
        )}
        
        <div className="auth-links">
          <p>Don't have an account? <Link to="/register">Register</Link></p>
          <p><Link to="/">Back to Home</Link></p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage; 