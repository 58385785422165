import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './LeaderboardPage.css';

const LeaderboardPage = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL || ''}/api/users/leaderboard`);
        setLeaderboard(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching leaderboard:');
        setError('Failed to load leaderboard data. Please try again later.');
        setLoading(false);
      }
    };

    fetchLeaderboard();
  }, []);

  if (loading) {
    return (
      <div className="leaderboard-page">
        <div className="leaderboard-container">
          <h1>Leaderboard</h1>
          <div className="loading">Loading leaderboard...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="leaderboard-page">
        <div className="leaderboard-container">
          <h1>Leaderboard</h1>
          <div className="error-message">{error}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="leaderboard-page">
      <div className="leaderboard-container">
        <h1>Leaderboard</h1>
        
        <div className="leaderboard-table">
          <div className="leaderboard-header">
            <div className="rank-column">Rank</div>
            <div className="player-column">Player</div>
            <div className="rating-column">Rating</div>
            <div className="games-column">Games</div>
            <div className="winrate-column">Solve Rate</div>
          </div>
          
          {leaderboard.length === 0 ? (
            <div className="no-data-message">No players found</div>
          ) : (
            leaderboard.map((player, index) => (
              <div key={player._id} className="leaderboard-row">
                <div className="rank-column">{index + 1}</div>
                <div className="player-column">{player.username}</div>
                <div className="rating-column">{player.rating}</div>
                <div className="games-column">{player.gamesPlayed}</div>
                <div className="winrate-column">
                  {player.gamesPlayed > 0 
                    ? ((player.wins / player.gamesPlayed) * 100).toFixed(1) + '%'
                    : '0.0%'
                  }
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default LeaderboardPage; 