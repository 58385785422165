import React, { useState, useEffect, useRef } from 'react';
import { useGame } from '../contexts/GameContext';
import { useAuth } from '../contexts/AuthContext';
import './GamePlayUI.css';
import { useNavigate } from 'react-router-dom';
import GameTimer from './GameTimer';

const GamePlayUI = ({ puzzle, roomId, players }) => {
  const { socket, leaveGame } = useGame();
  const { user, isGuest, guestId } = useAuth();
  const [selectedWords, setSelectedWords] = useState([]);
  const [words, setWords] = useState([]);
  const [playerProgress, setPlayerProgress] = useState({});
  const [playerLives, setPlayerLives] = useState({});
  const [completedCategories, setCompletedCategories] = useState({});
  const [foundGroups, setFoundGroups] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isShaking, setIsShaking] = useState(false);
  const [oneAwayMessage, setOneAwayMessage] = useState(null);
  const shuffledState = useRef(null);
  const wordGridRef = useRef(null);
  const wordIndicesRef = useRef(null); 
  const originalPuzzleRef = useRef(null);
  const [isPlayerDone, setIsPlayerDone] = useState(false);
  const navigate = useNavigate();
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [submissionHistory, setSubmissionHistory] = useState([]);
  const [isLeaving, setIsLeaving] = useState(false);
  const [showUnfoundCategories, setShowUnfoundCategories] = useState(false);
  
  
  const currentPlayerId = user?._id || (isGuest ? guestId : null);
  
 
  const categoryColors = {
    0: '#f5e07e', 
    1: '#a7c268', 
    2: '#b4c3e4', 
    3: '#b283c1'  
  };
  
  
  useEffect(() => {
    if (shuffledState.current) {
      localStorage.setItem(`shuffle-${roomId}`, JSON.stringify(shuffledState.current));
    }
  }, [shuffledState.current, roomId]);
  
 
  useEffect(() => {
    if (foundGroups.length > 0) {
      localStorage.setItem(`foundGroups-${roomId}`, JSON.stringify(foundGroups));
    }
  }, [foundGroups, roomId]);
  
  useEffect(() => {
    if (!socket) return;
    
    
    if (!isInitialized) {
      socket.emit('get_game_state', { roomId });
      setIsInitialized(true);
      
      
      const savedShuffle = localStorage.getItem(`shuffle-${roomId}`);
      if (savedShuffle) {
        shuffledState.current = JSON.parse(savedShuffle);
      }
      
     
      const savedFoundGroups = localStorage.getItem(`foundGroups-${roomId}`);
      if (savedFoundGroups) {
        setFoundGroups(JSON.parse(savedFoundGroups));
      }
      
     
      const savedStartTime = localStorage.getItem(`timer-start-${roomId}`);
      if (savedStartTime) {
       
        setIsTimerRunning(true);
      }
    }
    
   
    const handleGameState = (data) => {
      if (data.roomId === roomId) {
        
        
        if (!originalPuzzleRef.current && data.game.puzzle) {
          originalPuzzleRef.current = data.game.puzzle;
        }
        
       
        const puzzleToUse = originalPuzzleRef.current || data.game.puzzle;
        
       
        if (puzzleToUse && puzzleToUse.words) {
          setWords(puzzleToUse.words);
        }
        
       
        const livesData = {};
        data.game.players.forEach(player => {
          const playerId = player.userId || player.guestId;
          livesData[playerId] = player.lives;
        });
        setPlayerLives(livesData);
        
       
        const categoriesData = {};
        data.game.players.forEach(player => {
          const playerId = player.userId || player.guestId;
          if (player.completedCategories && player.completedCategories.length > 0) {
            categoriesData[playerId] = player.completedCategories;
          } else {
            categoriesData[playerId] = [];
          }
        });
        setCompletedCategories(categoriesData);
        
       
        if (currentPlayerId && categoriesData[currentPlayerId] && categoriesData[currentPlayerId].length > 0) {
          const playerCategories = categoriesData[currentPlayerId];
          
          
          const playerFoundGroups = [];
          
          playerCategories.forEach(categoryIndex => {
            if (puzzleToUse.connections && 
                puzzleToUse.categories && 
                puzzleToUse.connections[categoryIndex]) {
              const connection = puzzleToUse.connections[categoryIndex];
              const categoryWords = connection.map(index => puzzleToUse.words[index]);
              
              playerFoundGroups.push({
                categoryIndex,
                categoryName: puzzleToUse.categories[categoryIndex],
                words: categoryWords,
                color: categoryColors[categoryIndex % categoryColors.length]
              });
            }
          });
          
          
          if (playerFoundGroups.length > 0 && (foundGroups.length === 0 || 
              playerFoundGroups.length !== foundGroups.length)) {
            setFoundGroups(playerFoundGroups);
            
            localStorage.setItem(`foundGroups-${roomId}`, JSON.stringify(playerFoundGroups));
          }
        }
      }
    };
    
    
    const handlePlayerProgress = (data) => {
      setPlayerProgress(prev => ({
        ...prev,
        [data.playerId]: data.progress
      }));
    };
    
    
    const handlePlayerLives = (data) => {
      
      if (data.playerId === currentPlayerId) {
        const currentLives = playerLives[currentPlayerId] || 4;
        if (data.lives < currentLives) {
         
          setIsShaking(true);
          setTimeout(() => setIsShaking(false), 500);
          
         
          setSelectedWords([]);
        }
      }
      
      setPlayerLives(prev => ({
        ...prev,
        [data.playerId]: data.lives
      }));
    };
    
   
    const handleConnectionError = () => {
      
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 500);
      
      
      setSelectedWords([]);
    };
    
   
    const handleCompletedCategory = (data) => {
      
      setCompletedCategories(prev => {
        const playerCategories = prev[data.playerId] || [];
        
        if (!playerCategories.includes(data.categoryIndex)) {
          return {
            ...prev,
            [data.playerId]: [...playerCategories, data.categoryIndex]
          };
        }
        return prev;
      });
      
    
      if (data.playerId === currentPlayerId && data.words && data.categoryName) {
        
        setFoundGroups(prev => {
         
          const exists = prev.some(group => group.categoryIndex === data.categoryIndex);
          if (!exists) {
            const newFoundGroups = [
              ...prev, 
              {
                categoryIndex: data.categoryIndex,
                categoryName: data.categoryName,
                words: data.words,
                color: categoryColors[data.categoryIndex]
              }
            ];
            
            
            localStorage.setItem(`foundGroups-${roomId}`, JSON.stringify(newFoundGroups));
            
            return newFoundGroups;
          }
          return prev;
        });
        
       
        setSelectedWords([]);
      }
      
    
      if (data.playerId === currentPlayerId) {
        setSubmissionHistory(prev => {
          if (prev.length === 0) return prev;
          
          const updated = [...prev];
          const lastIndex = updated.length - 1;
          
          updated[lastIndex] = {
            ...updated[lastIndex],
            result: 'correct',
            categoryName: data.categoryName,
            categoryIndex: data.categoryIndex
          };
          
          localStorage.setItem(`submissions-${roomId}`, JSON.stringify(updated));
          
          return updated;
        });
      }
    };
    
    
    const handleConnectionIncorrect = (data) => {
     
      setSubmissionHistory(prev => {
        if (prev.length === 0) return prev;
        
        const updated = [...prev];
        const lastIndex = updated.length - 1;
        updated[lastIndex] = {
          ...updated[lastIndex],
          result: 'incorrect',
          oneAway: data.oneAway || false
        };
        
       
        localStorage.setItem(`submissions-${roomId}`, JSON.stringify(updated));
        
        return updated;
      });
      
    
      if (data.oneAway) {
        setOneAwayMessage("one away!");
        setTimeout(() => setOneAwayMessage(null), 3000);
      }
      
     
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 500);
      
      
      setSelectedWords([]);
    };
    
    
    const handleGameCompleted = (data) => {
      
    
      if (data.results) {
        
       
        const currentPlayerResult = data.results.find(p => 
          p.userId === currentPlayerId || p.guestId === currentPlayerId
        );
        
        
        if (currentPlayerResult && !currentPlayerResult.isGuest) {
        }
      }
      
    
    };
    
    socket.on('game_start', () => {
      setIsTimerRunning(true);
    });
    
    socket.on('game_completed', () => {
      setIsTimerRunning(false);
    });
    
    socket.on('player_completed', (data) => {
      if (data.playerId === currentPlayerId) {
        setIsTimerRunning(false);
      }
    });
    
    socket.on('game_state', handleGameState);
    socket.on('player_progress', handlePlayerProgress);
    socket.on('player_lives', handlePlayerLives);
    socket.on('category_completed', handleCompletedCategory);
    socket.on('connection_error', handleConnectionError);
    socket.on('connection_incorrect', handleConnectionIncorrect);
    socket.on('game_completed', handleGameCompleted);
    
    return () => {
      socket.off('game_state', handleGameState);
      socket.off('player_progress', handlePlayerProgress);
      socket.off('player_lives', handlePlayerLives);
      socket.off('category_completed', handleCompletedCategory);
      socket.off('connection_error', handleConnectionError);
      socket.off('connection_incorrect', handleConnectionIncorrect);
      socket.off('game_completed', handleGameCompleted);
      socket.off('game_start');
      socket.off('player_completed');
      
    
      socket.on('game_completed', () => {
        localStorage.removeItem(`shuffle-${roomId}`);
      });
    };
  }, [socket, roomId, categoryColors, currentPlayerId, isInitialized]);
  
  
  useEffect(() => {
   
    const playerHasNoLives = (playerLives[currentPlayerId] || 0) <= 0;
    const allCategoriesFound = foundGroups.length === 4;
    
    setIsPlayerDone(playerHasNoLives || allCategoriesFound);
    
  
    setShowUnfoundCategories(playerHasNoLives);
  }, [playerLives, foundGroups, currentPlayerId]);
  
  const handleWordClick = (word) => {
   
    const isWordInFoundGroup = foundGroups.some(group => 
      group.words.includes(word)
    );
    
    if (isWordInFoundGroup) return;
    
   
    if (selectedWords.includes(word)) {
      setSelectedWords(selectedWords.filter(w => w !== word));
    } else {
   
      if (selectedWords.length < 4) {
        const newSelectedWords = [...selectedWords, word];
        setSelectedWords(newSelectedWords);
      }
    }
  };
  
  const handleSubmit = () => {
  
    const currentPlayerLives = playerLives[currentPlayerId] || 0;
    if (currentPlayerLives <= 0) return;
    
    if (selectedWords.length === 4) {
      
      
      const submission = {
        words: [...selectedWords],
        time: currentTime,
        timestamp: new Date().toISOString()
      };
      
      setSubmissionHistory(prev => [...prev, submission]);
      
    
      localStorage.setItem(`submissions-${roomId}`, JSON.stringify([...submissionHistory, submission]));
      
      socket.emit('submit_connection', { 
        roomId, 
        words: selectedWords,
        submissionTime: currentTime
      });
    
    }
  };
  
  const handleShuffle = () => {
   
    const remainingWords = getRemainingWords();
    
    
    const shuffledRemaining = [...remainingWords].sort(() => Math.random() - 0.5);
    
    
    shuffledState.current = shuffledRemaining;
    
    
    localStorage.setItem(`shuffle-${roomId}`, JSON.stringify(shuffledRemaining));
    
   
    setWords(shuffledRemaining);
    
    const newSelectedWords = [];
    shuffledRemaining.forEach(word => {
      if (selectedWords.includes(word)) {
        newSelectedWords.push(word);
      }
    });
    
    setSelectedWords(newSelectedWords);
  };
  
  const getCurrentPlayerId = () => {
    if (isGuest) {
      return guestId;
    }
    return user?._id;
  };
  
 
  const renderCategoryBadges = (playerId) => {
    const categories = completedCategories[playerId] || [];
    
    return (
      <div className="category-badges">
        {[0, 1, 2, 3].map(categoryIndex => (
          <div 
            key={categoryIndex}
            className={`category-badge ${categories.includes(categoryIndex) ? 'completed' : 'incomplete'}`}
            style={{ 
              backgroundColor: categories.includes(categoryIndex) ? categoryColors[categoryIndex] : '#e0e0e0'
            }}
          />
        ))}
      </div>
    );
  };
  
  
  const getRemainingWords = () => {
    const foundWords = foundGroups.flatMap(group => group.words);
    return words.filter(word => !foundWords.includes(word));
  };
  
  
  const hasLives = () => {
    const lives = playerLives[currentPlayerId] || 0;
    return lives > 0;
  };
  
  
  
  const handleBackToLobby = async () => {
   
    setIsLeaving(true);
    
    try {
      
      const success = await leaveGame();
      
  
      if (success) {
        
        navigate('/');
      } else { 
       
        const savedFoundGroups = localStorage.getItem(`foundGroups-${roomId}`);

        localStorage.removeItem(`shuffle-${roomId}`);
        localStorage.removeItem(`submissions-${roomId}`);
        localStorage.removeItem(`timer-start-${roomId}`);

        if (savedFoundGroups) {
          localStorage.setItem(`foundGroups-${roomId}`, savedFoundGroups);
        }
        
        navigate('/');
      }
    } catch (error) {
      navigate('/');
    } finally {
      setIsLeaving(false);
    }
  };
  
  const handleDeselectAll = () => {
    if (selectedWords.length > 0) {
      setSelectedWords([]);
    }
  };
  
  useEffect(() => {
    if (!socket) return;
    
    socket.on('game_completed', (data) => {
      if (data.roomId === roomId) {
        setIsTimerRunning(false);
        localStorage.removeItem(`timer-start-${roomId}`);
      }
    });
    
    socket.on('player_completed', (data) => {
      if (data.playerId === currentPlayerId) {
        setIsTimerRunning(false);
        localStorage.removeItem(`timer-start-${roomId}`);
      }
    });
    
    return () => {
      socket.off('game_completed');
      socket.off('player_completed');
    };
  }, [socket, roomId, currentPlayerId]);
  
  const handleTimeUpdate = (time) => {
    setCurrentTime(time);
  };
  
  useEffect(() => {
    if (socket && roomId) {
      socket.emit('get_game_state', { roomId });
    }
    
    const savedFoundGroups = localStorage.getItem(`foundGroups-${roomId}`);
    if (savedFoundGroups && foundGroups.length === 0) {
      try {
        setFoundGroups(JSON.parse(savedFoundGroups));
      } catch (error) {
        console.error("Error parsing");
      }
    }
    
    const savedSubmissions = localStorage.getItem(`submissions-${roomId}`);
    if (savedSubmissions) {
      try {
        const parsedSubmissions = JSON.parse(savedSubmissions);
        if (Array.isArray(parsedSubmissions)) {
          setSubmissionHistory(parsedSubmissions);
        }
      } catch (error) {
        console.error('Error parsing saved submissions:');
      }
    }
  }, [roomId, socket, foundGroups.length]);
  
  const formatTime = (seconds) => {
    if (!seconds && seconds !== 0) return '--:--';
    
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    const tenths = Math.floor((seconds % 1) * 10);
    
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}.${tenths}`;
  };
  
  useEffect(() => {
    if (!socket) return;
    
    socket.on('game_state', (data) => {
      if (data.roomId === roomId) {
        
        const currentPlayer = data.game.players.find(player => {
          if (isGuest) {
            return player.guestId === currentPlayerId;
          } else {
            return player.userId === currentPlayerId;
          }
        });
        
        if (currentPlayer && currentPlayer.submissions && currentPlayer.submissions.length > 0) {
          const formattedSubmissions = currentPlayer.submissions.map(sub => ({
            words: sub.words,
            time: sub.elapsedSeconds || 0,
            timestamp: sub.time,
            result: sub.result,
            categoryName: sub.categoryName,
            oneAway: sub.oneAway
          }));
          
          if (formattedSubmissions.some(sub => sub.time > 0)) {
            setSubmissionHistory(formattedSubmissions);
            localStorage.setItem(`submissions-${roomId}`, JSON.stringify(formattedSubmissions));
          } else {
            const savedSubmissions = localStorage.getItem(`submissions-${roomId}`);
            if (savedSubmissions) {
              try {
                const parsedSubmissions = JSON.parse(savedSubmissions);
                if (Array.isArray(parsedSubmissions) && parsedSubmissions.length > 0) {
                  setSubmissionHistory(parsedSubmissions);
                }
              } catch (error) {
                console.error('Error parsing saved submissions:', error);
              }
            }
          }
        }
      }
    });
    
    
    return () => {
      socket.off('game_state');
    };
  }, [socket, roomId, currentPlayerId, isGuest]);
  
  const getUnfoundCategories = () => {
    if (!originalPuzzleRef.current) return [];
    
    const puzzle = originalPuzzleRef.current;
    
    const foundCategoryIndices = foundGroups.map(group => group.categoryIndex);
    
    return Array.from({ length: 4 }, (_, index) => {
      if (!foundCategoryIndices.includes(index)) {
        const connection = puzzle.connections[index];
        return {
          categoryIndex: index,
          categoryName: puzzle.categories[index],
          words: connection.map(wordIndex => puzzle.words[wordIndex]),
          color: categoryColors[index]
        };
      }
      return null;
    }).filter(category => category !== null);
  };
  
  if (isLeaving) {
    return (
      <div className="game-room-loading">
        <h2>Leaving Game</h2>
        <div className="loading-spinner"></div>
        <p>Please wait while we clean up...</p>
      </div>
    );
  }
  
  if (!puzzle) {
    return <div className="loading-puzzle">Loading puzzle...</div>;
  }
  
  return (
    <div className="game-play-container">
      <div className="game-header">
        <GameTimer 
          isRunning={isTimerRunning} 
          roomId={roomId}
          onTimeUpdate={handleTimeUpdate} 
        />
        <div className="player-stats">
          {players.map((player, index) => {
            const playerId = player.userId || player.guestId;
            const isCurrentPlayer = playerId === getCurrentPlayerId();
            
            return (
              <div key={index} className={`player-stat-card ${isCurrentPlayer ? 'current-player' : ''}`}>
                <div className="player-name">{player.username}</div>
                <div className="player-lives">
                  Lives: {playerLives[playerId] || 0}
                </div>
                {renderCategoryBadges(playerId)}
              </div>
            );
          })}
        </div>
      </div>
      
      <div className="found-groups">
        {foundGroups.map((group, index) => (
          <div 
            key={index}
            className="found-group"
            style={{ backgroundColor: group.color }}
          >
            <div className="group-category">{group.categoryName}</div>
            <div className="group-words">
              {group.words.join(', ')}
            </div>
          </div>
        ))}
      </div>
      
      <div className="word-grid-container">
        {oneAwayMessage && (
          <div className="one-away-overlay">
            {oneAwayMessage}
          </div>
        )}
        
        {!hasLives() && (
          <div className="unfound-categories">
            <h3>Missed Categories:</h3>
            {getUnfoundCategories().map((group, index) => (
              <div 
                key={index}
                className="found-group"
                style={{ backgroundColor: group.color }}
              >
                <div className="group-category">{group.categoryName}</div>
                <div className="group-words">
                  {group.words.join(', ')}
                </div>
              </div>
            ))}
          </div>
        )}
        
        {hasLives() && (
          <div 
            ref={wordGridRef}
            className={`word-grid ${isShaking ? 'shake' : ''}`}
          >
            {getRemainingWords().map((word, index) => (
              <div 
                key={index}
                className={`word-card ${selectedWords.includes(word) ? 'selected' : ''}`}
                onClick={() => handleWordClick(word)}
              >
                {word}
              </div>
            ))}
          </div>
        )}
      </div>
      
      <div className="game-instructions">
        <p>Select 4 words that you think belong together</p>
      </div>
      
      <div className="game-controls">
        <button 
          className="shuffle-button"
          onClick={handleShuffle}
        >
          Shuffle
        </button>
        
        <button 
          className={`deselect-button ${selectedWords.length > 0 ? 'active' : 'disabled'}`}
          onClick={handleDeselectAll}
          disabled={selectedWords.length === 0}
        >
          Deselect All
        </button>
        
        <button 
          className={`submit-button ${selectedWords.length === 4 && hasLives() ? 'active' : 'disabled'}`}
          onClick={handleSubmit}
          disabled={selectedWords.length !== 4 || !hasLives()}
        >
          Submit
        </button>
      </div>
      
      {!hasLives() && (
        <div className="no-lives-message">
          You have no lives left! Watch the status of other players or return to the lobby.
        </div>
      )}
      
      {isPlayerDone && (
        <button 
          className="back-to-lobby-button"
          onClick={handleBackToLobby}
        >
          Back to Lobby
        </button>
      )}
      
      {submissionHistory.length > 0 && (
        <div className="submission-history">
          <h3>Submission History</h3>
          <ul>
            {submissionHistory.map((submission, index) => (
              <li key={index} className={`submission-item ${submission.result || 'pending'}`}>
                <div className="submission-time">
                  {formatTime(submission.time)}
                </div>
                <div className="submission-words">
                  {submission.words.join(', ')}
                </div>
                <div className="submission-result">
                  {submission.result === 'correct' ? 
                    `✓ ${submission.categoryName}` : 
                    submission.result === 'incorrect' ? 
                      (submission.oneAway ? '× (one away!)' : '×') : 
                      '...'}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default GamePlayUI; 