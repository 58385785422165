import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import './AuthPages.css';

const RegisterPage = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const { register, playAsGuest } = useAuth();
  const navigate = useNavigate();

  const validateUsername = (username) => {
    if (/^guest_/i.test(username)) {
      return "Username cannot start with 'guest_'";
    }
    
    if (username.length < 3) {
      return "Username must be at least 3 characters";
    }
    
    if (username.length > 20) {
      return "Username cannot exceed 20 characters";
    }
    
    if (!/^[a-zA-Z0-9_]+$/.test(username)) {
      return "Username can only contain letters, numbers, and underscores";
    }
    
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!username || !email || !password || !confirmPassword) {
      setError('Please fill in all required fields');
      return;
    }
    
    const usernameError = validateUsername(username);
    if (usernameError) {
      setError(usernameError);
      return;
    }
    
    if (password.length < 6) {
      setError('Password must be at least 6 characters');
      return;
    }
    
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    
    try {
      setError('');
      setLoading(true);
      const result = await register(username, email, password);
      if (result.success) {
        setSuccess('Registration successful! Please check your email to verify your account.');
      } else {
        setError(result.message || 'Registration failed. Username or email is already taken.');
      }
    } catch (err) {
      if (err.message && err.message.includes('shorter than the minimum allowed length')) {
        setError('Username must be at least 3 characters long');
      } else {
        setError(err.message || 'Registration failed');
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePlayAsGuest = () => {
    playAsGuest();
    navigate('/');
  };

  return (
    <div className="auth-page">
      <div className="auth-container">
        <h1>Register</h1>
        {error && <div className="auth-error">{error}</div>}
        {success && <div className="auth-success">{success}</div>}
        
        {!success ? (
          <>
            <form onSubmit={handleSubmit} className="auth-form">
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                  className="form-control"
                  placeholder="Must be at least 3 characters"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="form-control"
                  placeholder="Required for account verification"
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="form-control"
                  placeholder="Must be at least 6 characters"
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  className="form-control"
                />
              </div>
              <button 
                type="submit" 
                className="auth-button"
                disabled={loading}
              >
                {loading ? 'Registering...' : 'Register'}
              </button>
            </form>
            
            <div className="auth-divider">
              <span>OR</span>
            </div>
            
            <button 
              onClick={handlePlayAsGuest} 
              className="guest-button"
            >
              Play as Guest
            </button>
          </>
        ) : (
          <div className="verification-instructions">
            <p>We've sent a verification link to your email address.</p>
            <p>Please check your inbox and click the link to activate your account.</p>
            <div className="auth-links">
              <p>Already have an account? <Link to="/login" className="auth-link">Login</Link></p>
              <p><Link to="/" className="auth-link">Back to Home</Link></p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisterPage; 