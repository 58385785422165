import axios from 'axios';

const PING_INTERVAL = 5 * 60 * 1000; // 5 minutes in milliseconds

let pingInterval = null;

export const startPingService = () => {
  if (pingInterval) {
    clearInterval(pingInterval);
  }
  
  const serverUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
  
  // Immediately ping once
  pingServer(serverUrl);
  
  // Set up regular interval
  pingInterval = setInterval(() => {
    pingServer(serverUrl);
  }, PING_INTERVAL);
  
  return () => {
    if (pingInterval) {
      clearInterval(pingInterval);
      pingInterval = null;
    }
  };
};

const pingServer = async (serverUrl) => {
  try {
    const timestamp = new Date().toISOString();
    const response = await axios.get(`${serverUrl}/api/ping?t=${timestamp}`);
    console.log('Server ping successful:', response.status);
  } catch (error) {
    console.error('Server ping failed:', error.message);
  }
}; 