import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { useAuth } from './AuthContext';
import { startPingService } from '../utils/pingService';

const GameContext = createContext();

export const useGame = () => useContext(GameContext);

export const GameProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [connected, setConnected] = useState(false);
  const [currentGame, setCurrentGame] = useState(null);
  const [publicGames, setPublicGames] = useState([]);
  const [searching, setSearching] = useState(false);
  const { user, token, isGuest, guestId, checkIsAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [isProcessingGameAction, setIsProcessingGameAction] = useState(false);
  const [gameOperationInProgress, setGameOperationInProgress] = useState(false);
  const gameOperationQueue = useRef([]);

  useEffect(() => {
    if (!checkIsAuthenticated()) {
      return;
    }

    const newSocket = io(process.env.REACT_APP_API_URL || 'http://localhost:5001', {
      transports: ['websocket', 'polling'],
      autoConnect: true,
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      path: '/socket.io/'
    });

    newSocket.on('connect', () => {
      setConnected(true);
      
      if (token) {
        newSocket.emit('authenticate', { token });
      } else if (isGuest && guestId) {
        newSocket.emit('authenticate', { isGuest: true, guestId });
      } else {
        console.log('No authentication credentials available');
      }
    });

    newSocket.on('reconnect', () => {
      if (token) {
        newSocket.emit('authenticate', { token });
      } else if (isGuest && guestId) {
        newSocket.emit('authenticate', { isGuest: true, guestId });
      }
      
      if (currentGame && currentGame.roomId) {
        setTimeout(() => {
          newSocket.emit('rejoin_room', { 
            roomId: currentGame.roomId,
            guestId: isGuest ? guestId : null 
          });
        }, 1000);
      }
      
      setTimeout(() => {
        newSocket.emit('get_public_games');
      }, 1500);
    });

    newSocket.on('disconnect', () => {
      setConnected(false);
    });

    newSocket.on('connect_error', (error) => {
      setConnected(false);
    });

    newSocket.on('authenticated', (data) => {
      console.log('Authentication successful:', data);
    });

    newSocket.on('auth_error', (error) => {
      console.error('Authentication error:', error);
      alert(`Authentication error: ${error.message}`);
    });

    newSocket.on('room_created', (data) => {
      setCurrentGameWithStorage(data.game);
      navigate(`/game/${data.roomId}`);
    });

    newSocket.on('room_joined', (data) => {
      setCurrentGameWithStorage(data.game);
      navigate(`/game/${data.roomId}`);
    });

    newSocket.on('game_error', (data) => {
      console.error('Game error:');
      alert(data.message);
      
      if (data.gameId) {
        const storedGame = sessionStorage.getItem('currentGame');
        if (storedGame) {
          try {
            const parsedGame = JSON.parse(storedGame);
            if (parsedGame.roomId === data.gameId) {
              if (window.confirm('Return to your existing game?')) {
                returnToActiveGame();
              }
            }
          } catch (e) {
            console.error('Error parsing stored game:');
          }
        }
      }
    });

    newSocket.on('public_games', (games) => {
      setPublicGames(games);
    });

    newSocket.on('forced_disconnect', (data) => {
      alert(data.message);
      
      setCurrentGameWithStorage(null);
      
      navigate('/');
      
      newSocket.connect();
    });

    newSocket.on('player_left', (data) => {
      
      const currentUserId = isGuest ? guestId : user?._id;
      const playerId = data.playerId;
      
      if (currentUserId === playerId && currentGame) {
        setCurrentGameWithStorage(null);
      } else if (currentGame && currentGame.roomId === data.game.roomId) {
        setCurrentGameWithStorage(data.game);
      }
    });

    setSocket(newSocket);

    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, [token, isGuest, guestId, checkIsAuthenticated, navigate]);

  useEffect(() => {
    if (socket && connected) {
      const handlePublicGamesUpdate = (data) => {
        setPublicGames(data.games || []);
      };
      
      socket.on('public_games_update', handlePublicGamesUpdate);
      
      return () => {
        socket.off('public_games_update', handlePublicGamesUpdate);
      };
    }
  }, [socket, connected]);

  useEffect(() => {
    if (socket && connected) {
      // Set up a ping interval
      const pingInterval = setInterval(() => {
        socket.emit('client_ping', { timestamp: new Date().toISOString() });
      }, 4 * 60 * 1000); // 4 minutes
      
      // Handle pong response
      const handlePong = (data) => {
        console.log('Received pong from server:', data.timestamp);
      };
      
      socket.on('server_pong', handlePong);
      
      return () => {
        clearInterval(pingInterval);
        socket.off('server_pong', handlePong);
      };
    }
  }, [socket, connected]);

  const checkActiveGame = () => {
    return currentGame !== null;
  };

  const returnToActiveGame = () => {
    if (currentGame && currentGame.roomId) {
      navigate(`/game/${currentGame.roomId}`);
      return true;
    }
    return false;
  };

  const createGame = (options) => {
    if (checkActiveGame()) {
      if (window.confirm('You are already in a game. Return to that game?')) {
        returnToActiveGame();
        return;
      }
      alert('You must leave your current game before creating a new one.');
      return;
    }

    if (!socket || !connected) {
      alert('Not connected to server, please refresh the page and try again.');
      return;
    }

    try {
      if (isGuest) {
        options.guestOnly = true;
      }
      
      socket.emit('create_room', options);
    } catch (error) {
      console.error('Error creating game:');
    }
  };

  const processGameOperationQueue = () => {
    if (gameOperationQueue.current.length > 0 && !gameOperationInProgress) {
      setGameOperationInProgress(true);
      const nextOperation = gameOperationQueue.current.shift();
      nextOperation().finally(() => {
        setGameOperationInProgress(false);
        setTimeout(processGameOperationQueue, 800);
      });
    }
  };

  const queueGameOperation = (operation) => {
    return new Promise((resolve) => {
      gameOperationQueue.current.push(async () => {
        try {
          const result = await operation();
          resolve(result);
        } catch (error) {
          console.error("Game operation failed:");
          resolve(false);
        }
      });
      
      processGameOperationQueue();
    });
  };

  const joinGame = (roomId) => {
    return queueGameOperation(async () => {
      if (!socket || !connected) {
        alert('Not connected to server, please refresh the page and try again.');
        return Promise.resolve(false);
      }

      // Check if user is already in a game
      if (currentGame && currentGame.roomId !== roomId) {
        alert('You are already in a game. Please leave your current game before joining another one.');
        return Promise.resolve(false);
      }

      setIsProcessingGameAction(true);
      
      return new Promise((resolve) => {
        const handleGameError = (data) => {
          alert(data.message);
          setIsProcessingGameAction(false);
          socket.off('game_error', handleGameError);
          socket.off('room_joined', handleRoomJoined);
          resolve(false);
        };
        
        const handleRoomJoined = (data) => {
          setIsProcessingGameAction(false);
          socket.off('game_error', handleGameError);
          socket.off('room_joined', handleRoomJoined);
          resolve(true);
        };
        
        socket.on('game_error', handleGameError);
        socket.on('room_joined', handleRoomJoined);
        
        socket.emit('join_room', { roomId });
        
        setTimeout(() => {
          socket.off('game_error', handleGameError);
          socket.off('room_joined', handleRoomJoined);
          setIsProcessingGameAction(false);
          resolve(false);
        }, 5000);
      });
    });
  };

  const fetchPublicGames = () => {
    if (socket && connected) {
      socket.emit('get_public_games');
    }
  };

  const leaveGame = () => {
    return queueGameOperation(async () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          
          setCurrentGameWithStorage(null);
          
          socket.emit('leave_room', { roomId: currentGame.roomId });
          
          setTimeout(() => {
            setIsProcessingGameAction(false);
          }, 1000);
          
          resolve(true);
        }, 2000);
      });
    });
  };

  const setCurrentGameWithStorage = (game) => {
    setCurrentGame(game);
    if (game) {
      sessionStorage.setItem('currentGame', JSON.stringify(game));
    } else {
      sessionStorage.removeItem('currentGame');
    }
  };

  useEffect(() => {
    const storedGame = sessionStorage.getItem('currentGame');
    if (storedGame) {
      try {
        setCurrentGame(JSON.parse(storedGame));
      } catch (e) {
        console.error('Error parsing stored game:');
        sessionStorage.removeItem('currentGame');
      }
    }
  }, []);

  // Start the ping service when the component mounts
  useEffect(() => {
    const stopPingService = startPingService();
    
    // Clean up when the component unmounts
    return () => {
      stopPingService();
    };
  }, []);

  const value = {
    socket,
    connected,
    currentGame,
    publicGames,
    searching,
    createGame,
    joinGame,
    fetchPublicGames,
    leaveGame,
    checkActiveGame,
    returnToActiveGame
  };
  
  return (
    <GameContext.Provider value={value}>
      {children}
    </GameContext.Provider>
  );
}; 