import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useGame } from '../contexts/GameContext';
import './Navbar.css';

const Navbar = () => {
  const navigate = useNavigate();
  const { checkIsAuthenticated, logout, user, isGuest } = useAuth();
  const { currentGame } = useGame();
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const savedDarkMode = localStorage.getItem('darkMode') === 'true';
    
    setDarkMode(savedDarkMode);
    
    if (savedDarkMode) {
      document.documentElement.classList.add('dark-mode');
      document.body.classList.add('dark-mode');
    } else {
      document.documentElement.classList.remove('dark-mode');
      document.body.classList.remove('dark-mode');
    }
  }, []);

  const toggleDarkMode = () => {
    const isDarkMode = document.documentElement.classList.contains('dark-mode');
    const newDarkMode = !isDarkMode;
    
    setDarkMode(newDarkMode);
    
    if (newDarkMode) {
      document.documentElement.classList.add('dark-mode');
      document.body.classList.add('dark-mode');
    } else {
      document.documentElement.classList.remove('dark-mode');
      document.body.classList.remove('dark-mode');
    }
    
    localStorage.setItem('darkMode', newDarkMode);
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-brand">
          <Link to="/">Connections Racer</Link>
        </div>
        
        <div className="navbar-menu">
          <Link to="/" className="navbar-item">Home</Link>
          <Link to="/leaderboard" className="navbar-item">Leaderboard</Link>
          
          {checkIsAuthenticated() ? (
            <>
              {!isGuest && user?.username && (
                <Link to={`/profile/${user.username}`} className="navbar-item">
                  {user.username || 'Profile'}
                </Link>
              )}
              
              <button onClick={handleLogout} className="navbar-button logout-button">
                Logout
              </button>
            </>
          ) : (
            <>
              <Link to="/login" className="navbar-item">Login</Link>
            </>
          )}
        </div>
      </div>
      
      <button onClick={toggleDarkMode} className="theme-toggle-fixed">
        {darkMode ? '☀️' : '🌙'}
      </button>
    </nav>
  );
};

export default Navbar; 