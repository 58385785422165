import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import '../pages/AuthPages.css';

const VerifyEmail = () => {
  const [verifying, setVerifying] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const { token } = useParams();
  const [verificationAttempted, setVerificationAttempted] = useState(false);

  useEffect(() => {
    const verifyEmail = async () => {
      if (verificationAttempted) return;
      setVerificationAttempted(true);
      
      try {
        const response = await axios.get(`/api/auth/verify-email/${token}`);
        setSuccess(true);
        setVerifying(false);
        setError('');
      } catch (err) {
        setError(err.response?.data?.message || 'Verification failed');
        setVerifying(false);
        setSuccess(false);
      }
    };

    if (token) {
      verifyEmail();
    }
  }, [token, verificationAttempted]);

  return (
    <div className="auth-page">
      <div className="auth-container">
        <h1>Email Verification</h1>
        
        {verifying && <p>Verifying your email...</p>}
        
        {success && (
          <div className="auth-success">
            <p>Your email has been successfully verified!</p>
            <Link to="/login" className="auth-button login-button">
              Login to your account
            </Link>
          </div>
        )}
        
        {error && !success && (
          <div className="auth-error">
            <p>{error}</p>
            <p>The verification link may have expired or is invalid.</p>
            <Link to="/" className="auth-button">Return to Home</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail; 