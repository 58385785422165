import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import './ProfilePage.css';

const ProfilePage = () => {
  const navigate = useNavigate();
  const { username } = useParams();
  const { user: currentUser, token, fetchUserData, updateUsername } = useAuth();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 5;
  const retryDelay = 1500;
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
  const [expandedGame, setExpandedGame] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    let isMounted = true;

    const fetchUserProfile = async () => {
      try {
        setLoading(true);
        setError(null);
        
        if (currentUser && username === currentUser.username) {
          setUser(currentUser);
          setLoading(false);
          return;
        }
        
        const config = {
          headers: { 'x-auth-token': token }
        };
        
        const response = await axios.get(`${apiUrl}/api/users/profile/${username}`, config);
        
        if (isMounted) {
          setUser(response.data);
          setLoading(false);
        }
      } catch (err) {
        console.error('Error fetching profile:');
        
        if (isMounted) {
          if (err.response && err.response.status === 404) {
            setError(`User "${username}" not found`);
          } else {
            setError(err.response?.data?.msg || 'Failed to load profile data');
          }
          setLoading(false);
        }
      }
    };

    fetchUserProfile();

    return () => {
      isMounted = false;
    };
  }, [username, token, currentUser, apiUrl]);

  useEffect(() => {
    if (user && user.gameHistory) {
      user.gameHistory.forEach((game, index) => {
        console.log(`Game ${index} submissions:`, game.submissions);
      });
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setNewUsername(user.username);
    }
  }, [user]);

  const handleRetry = () => {
    if (isEditing) {
      setError(null);
      return;
    }
    
    setError(null);
    setLoading(true);
    setRetryCount(0);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  };
  
  const formatTime = (seconds) => {
    if (!seconds && seconds !== 0) return 'DNF';
    
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = (seconds % 60).toFixed(1);
    
    if (minutes > 0) {
      return `${minutes}m ${remainingSeconds}s`;
    } else {
      return `${remainingSeconds}s`;
    }
  };
  
  const formatSubmissionTime = (timeValue) => {
    if (!timeValue && timeValue !== 0) return '--:--';
    
    const minutes = Math.floor(timeValue / 60);
    const secs = Math.floor(timeValue % 60);
    const tenths = Math.floor((timeValue % 1) * 10);
    
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}.${tenths}`;
  };
  
  const toggleGameDetails = (index) => {
    if (expandedGame === index) {
      setExpandedGame(null);
    } else {
      setExpandedGame(index);
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    setError('');
    setSuccess('');
  };

  const handleUsernameChange = async (e) => {
    e.preventDefault();
    
    if (newUsername === user.username) {
      setIsEditing(false);
      return;
    }
    
    const usernameError = validateUsername(newUsername);
    if (usernameError) {
      setError(usernameError);
      return;
    }
    
    try {
      const saveButton = document.querySelector('.save-button');
      if (saveButton) saveButton.textContent = 'Saving...';
      
      setError('');
      
      const result = await updateUsername(newUsername);
      
      if (result.success) {
        setUser(prev => ({...prev, username: newUsername}));
        setSuccess(result.message || 'Username updated successfully!');
        setIsEditing(false);
        
        navigate(`/profile/${newUsername}`, { replace: true });
      } else {
        setError(result.message);
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Failed to update username';
      setError(errorMessage);
    } finally {
      const saveButton = document.querySelector('.save-button');
      if (saveButton) saveButton.textContent = 'Save';
    }
  };

  const validateUsername = (username) => {
    if (/^guest_/i.test(username)) {
      return "Username cannot start with 'guest_'";
    }
    
    if (username.length < 3) {
      return "Username must be at least 3 characters";
    }
    
    if (username.length > 20) {
      return "Username cannot exceed 20 characters";
    }
    
    if (!/^[a-zA-Z0-9_]+$/.test(username)) {
      return "Username can only contain letters, numbers, and underscores";
    }
    
    return null;
  };

  if (loading) {
    return (
      <div className="profile-page">
        <div className="profile-container">
          <div className="loading-container">
            <h1>Loading Profile</h1>
            <p>Please wait while we retrieve the profile data...</p>
          </div>
        </div>
      </div>
    );
  }

  if (error || !user) {
    return (
      <div className="profile-page">
        <div className="profile-container">
          <div className="error-container">
            <h1>Error</h1>
            <p className="error-message">{error || 'User not found'}</p>
            <button className="retry-button" onClick={handleRetry}>Retry</button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="profile-page">
      <div className="profile-container">
        <div className="profile-header">
          <div className="profile-avatar">
            {user.avatar ? (
              <img src={user.avatar} alt="Profile" />
            ) : (
              <div className="avatar-placeholder">
                {user.username.charAt(0).toUpperCase()}
              </div>
            )}
          </div>
          <div className="profile-info">
            {isEditing ? (
              <form onSubmit={handleUsernameChange} className="username-edit-form">
                <input
                  type="text"
                  value={newUsername}
                  onChange={(e) => setNewUsername(e.target.value)}
                  className="username-input"
                />
                <div className="edit-buttons">
                  <button 
                    type="submit" 
                    className="save-button"
                  >
                    Save
                  </button>
                  <button 
                    type="button" 
                    onClick={handleEditToggle} 
                    className="cancel-button"
                  >
                    Cancel
                  </button>
                </div>
                {error && <p className="error-message">{error}</p>}
              </form>
            ) : (
              <>
                <div className="username-container">
                  <h1>{user.username}</h1>
                  {currentUser && username === currentUser.username && (
                    <button onClick={handleEditToggle} className="edit-button">Edit</button>
                  )}
                </div>
                {success && <p className="success-message">{success}</p>}
              </>
            )}
            <p className="profile-rating">Rating: {user.rating || 1000}</p>
          </div>
        </div>
        
        <div className="profile-stats">
          <div className="stat-card">
            <div className="stat-value">{user.gamesPlayed || 0}</div>
            <div className="stat-label">Games Played</div>
          </div>
          <div className="stat-card">
            <div className="stat-value">{user.wins || 0}</div>
            <div className="stat-label">Solves</div>
          </div>
          <div className="stat-card">
            <div className="stat-value">
              {user.gamesPlayed ? ((user.wins / user.gamesPlayed) * 100).toFixed(1) : 0}%
            </div>
            <div className="stat-label">Solve Rate</div>
          </div>
        </div>
        
        <div className="profile-section">
          <h2>Game History</h2>
          <div className="game-history">
            {user.gameHistory && user.gameHistory.length > 0 ? (
              <div className="game-history-list">
                {user.gameHistory.map((game, index) => {
                  const shouldBeWin = (game.completedCategories === 4 || game.completedCategories === '4/4');
                  const displayResult = shouldBeWin ? 'SOLVED' : 'FAILED';
                  
                  return (
                    <div key={index} className="game-history-item">
                      <div 
                        className="game-header" 
                        onClick={() => toggleGameDetails(index)}
                      >
                        <div className="game-date">{formatDate(game.date)}</div>
                        <div className={`game-result ${shouldBeWin ? 'solved-result' : 'failed-result'}`}>
                          {displayResult}
                        </div>
                        <div className="game-stats">
                          <span>Solved: {game.completedCategories || 0}/4</span>
                          {game.timeTaken && (
                            <span className="game-time">
                              • Time: {formatTime(game.timeTaken)}
                            </span>
                          )}
                        </div>
                        <div className="expand-icon">
                          {expandedGame === index ? '▼' : '▶'}
                        </div>
                      </div>
                      
                      {expandedGame === index && (
                        <div className="submission-container">
                          <h4>Submission History</h4>
                          {game.submissions && game.submissions.length > 0 ? (
                            <ul className="submission-list">
                              {game.submissions.map((submission, index) => {
                                return (
                                  <li key={index} className={`submission-item ${submission.categoryName || submission.result === 'correct' ? 'correct' : 'incorrect'}`}>
                                    <div className="submission-time">
                                      {submission.formattedTime || formatSubmissionTime(submission.elapsedSeconds)}
                                    </div>
                                    <div className="submission-words">
                                      {submission.words ? submission.words.join(', ') : 'No words recorded'}
                                    </div>
                                    <div className="submission-result">
                                      {submission.result === 'correct' ? 
                                        `✓ ${submission.categoryName}` : 
                                        submission.oneAway ? 
                                          '× (one away!)' : 
                                          '×'}
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          ) : (
                            <p>No submissions recorded</p>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className="empty-state">No games played yet</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;