import React, { useState, useEffect, useRef } from 'react';
import './GameTimer.css';

const GameTimer = ({ isRunning = true, roomId, onTimeUpdate }) => {
  const [elapsedTime, setElapsedTime] = useState(0);
  const intervalRef = useRef(null);
  const startTimeRef = useRef(null);
  
  useEffect(() => {
    const savedStartTime = localStorage.getItem(`timer-start-${roomId}`);
    
    if (isRunning) {
      if (savedStartTime) {
        startTimeRef.current = parseInt(savedStartTime, 10);
      } else {
        startTimeRef.current = Date.now();
        localStorage.setItem(`timer-start-${roomId}`, startTimeRef.current.toString());
      }
      
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      
      intervalRef.current = setInterval(() => {
        const currentTime = Date.now();
        const newElapsedTime = (currentTime - startTimeRef.current) / 1000;
        setElapsedTime(newElapsedTime);
        
        if (onTimeUpdate) {
          onTimeUpdate(newElapsedTime);
        }
      }, 100);
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current);
      
      if (!isRunning) {
        localStorage.removeItem(`timer-start-${roomId}`);
      }
    }
    
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isRunning, roomId, onTimeUpdate]);
  
  const formatTime = () => {
    const minutes = Math.floor(elapsedTime / 60);
    const seconds = Math.floor(elapsedTime % 60);
    const tenths = Math.floor((elapsedTime % 1) * 10);
    
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${tenths}`;
  };
  
  return (
    <div className="game-timer">
      <span className="timer-label">Time:</span>
      <span className="timer-value">{formatTime()}</span>
    </div>
  );
};

export default GameTimer; 