import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useGame } from '../contexts/GameContext';
import { useAuth } from '../contexts/AuthContext';
import './GameRoomPage.css';
import GamePlayUI from '../components/GamePlayUI';

const GameRoomPage = () => {
  const { roomId } = useParams();
  const { socket, connected, currentGame, leaveGame, isProcessingGameAction } = useGame();
  const { user, isGuest, token, guestId } = useAuth();
  const [players, setPlayers] = useState([]);
  const [gameStatus, setGameStatus] = useState('waiting');
  const [countdown, setCountdown] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [puzzle, setPuzzle] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isLeaving, setIsLeaving] = useState(false);
  const [leaveButtonEnabled, setLeaveButtonEnabled] = useState(true);

  useEffect(() => {
    if (!connected || !socket) {
      return;
    }

    if (currentGame && currentGame.roomId === roomId) {
      setPlayers(currentGame.players);
      setGameStatus(currentGame.status);
      setPuzzle(currentGame.puzzle);
      
      const currentPlayer = currentGame.players.find(p => 
        (p.userId === (user?._id || null)) || (p.guestId === (isGuest ? guestId : null))
      );
      if (currentPlayer) {
        setIsReady(currentPlayer.status === 'ready');
      }
    } else {
      socket.emit('join_room', { roomId });
    }

    const handlePlayerJoined = (data) => {
      setPlayers(data.game.players);
      setGameStatus(data.game.status);
    };

    const handlePlayerLeft = (data) => {
      setPlayers(data.game.players);
    };

    const handleGameUpdate = (data) => {
      if (data.game.roomId === roomId) {
        setPlayers(data.game.players);
        setGameStatus(data.game.status);
        
        const currentPlayer = data.game.players.find(p => {
          if (p.userId && user?._id) {
            return p.userId === user._id;
          }
          if (p.guestId && isGuest && guestId) {
            return p.guestId === guestId;
          }
          return false;
        });
        
        if (currentPlayer) {
          setIsReady(currentPlayer.status === 'ready');
        }
      }
    };
    
    const handleRoomJoined = (data) => {
      if (data.roomId === roomId) {
        setPlayers(data.game.players);
        setGameStatus(data.game.status);
        
        const currentPlayer = data.game.players.find(p => {
          if (p.userId && user?._id) {
            return p.userId === user._id;
          }
          if (p.guestId && isGuest && guestId) {
            return p.guestId === guestId;
          }
          return false;
        });
        
        if (currentPlayer) {
          setIsReady(currentPlayer.status === 'ready');
        }
      }
    };
    
    const handleCountdown = (data) => {
      setCountdown(data.count);
    };

    const handleGameStart = (data) => {
      setGameStatus('playing');
      setCountdown(null);
      
      if (data.puzzle) {
        setPuzzle(data.puzzle);
      }
    };

    const handleGameError = (error) => {
      console.error('Game error:');
      if (error.message === 'Authentication required') {
        if (token) {
          socket.emit('authenticate', { token });
        } else if (isGuest && guestId) {
          socket.emit('authenticate', { isGuest: true, guestId });
        }
        
        setTimeout(() => {
          socket.emit('join_room', { roomId });
        }, 1000);
      }
    };

    const handlePlayerRejoined = (data) => {
      setPlayers(data.game.players);
    };

    const handleReconnectedToGame = (data) => {
      if (data.roomId === roomId) {
        setPlayers(data.game.players);
        setGameStatus(data.game.status);
        
        const currentPlayer = data.game.players.find(p => 
          (p.userId === (user?._id || null)) || (p.guestId === (isGuest ? guestId : null))
        );
        if (currentPlayer) {
          setIsReady(currentPlayer.status === 'ready');
        }
      }
    };

    const handleGameState = (data) => {
      if (data.roomId === roomId) {
        setPlayers(data.game.players);
        setGameStatus(data.game.status);
        
        if (data.game.puzzle) {
          setPuzzle(data.game.puzzle);
        }
        
        const currentPlayer = data.game.players.find(p => 
          (p.userId === (user?._id || null)) || (p.guestId === (isGuest ? guestId : null))
        );
        if (currentPlayer) {
          setIsReady(currentPlayer.status === 'ready');
        }
      }
    };

    socket.on('player_joined', handlePlayerJoined);
    socket.on('player_left', handlePlayerLeft);
    socket.on('game_update', handleGameUpdate);
    socket.on('room_joined', handleRoomJoined);
    socket.on('game_countdown', handleCountdown);
    socket.on('game_start', handleGameStart);
    socket.on('game_error', handleGameError);
    socket.on('player_rejoined', handlePlayerRejoined);
    socket.on('reconnected_to_game', handleReconnectedToGame);
    socket.on('game_state', handleGameState);

    socket.emit('get_game_state', { roomId });

    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    
    return () => {
      socket.off('player_joined', handlePlayerJoined);
      socket.off('player_left', handlePlayerLeft);
      socket.off('game_update', handleGameUpdate);
      socket.off('room_joined', handleRoomJoined);
      socket.off('game_countdown', handleCountdown);
      socket.off('game_start', handleGameStart);
      socket.off('game_error', handleGameError);
      socket.off('player_rejoined', handlePlayerRejoined);
      socket.off('reconnected_to_game', handleReconnectedToGame);
      socket.off('game_state', handleGameState);
      clearTimeout(timer);
    };
  }, [connected, socket, roomId, currentGame, user, isGuest, guestId]);

  const handleLeaveGame = async () => {
    setIsLeaving(true);
    
    const success = await leaveGame();
    
    if (success) {
      navigate('/');
    } else {
      setIsLeaving(false);
    }
  };

  const handleToggleReady = () => {
    if (!connected || !socket) {
      alert('Not connected to server, please refresh the page and try again.');
      return;
    }
    
    console.log('Toggling ready state for room:', roomId);
    socket.emit('toggle_ready', { roomId });
  };

  const handleCopyCode = () => {
    navigator.clipboard.writeText(roomId);
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 2000);
  };

  const getPlayerName = (player) => {
    if (player.username) {
      return player.username;
    } else if (player.userId && player.userId.username) {
      return player.userId.username;
    } else if (player.guestId) {
      return `Guest_${player.guestId.substring(0, 6)}`;
    }
    return 'Player';
  };

  const isAllPlayersReady = () => {
    return players.length > 0 && players.every(p => p.status === 'ready');
  };

  if (loading || isProcessingGameAction || isLeaving) {
    return (
      <div className="game-room-loading">
        <h2>{isLeaving ? 'Leaving Game' : 'Loading Game Room'}</h2>
        <div className="loading-spinner"></div>
        <p>{isLeaving ? 'Please wait while we clean up...' : 'Please wait while we set up your game'}</p>
      </div>
    );
  }

  return (
    <div className="game-room-page">
      {countdown !== null && (
        <div className="countdown-overlay">
          <div className="countdown-number">{countdown}</div>
        </div>
      )}
      
      <div className="game-room-header">
        <h1>Game Room: {roomId}</h1>
        <div className="game-status">
          <div className="status-badge">Status: {gameStatus}</div>
        </div>
      </div>

      {gameStatus === 'waiting' ? (
        <div className="game-room-content">
          <div className="players-section">
            <h2>Players</h2>
            <div className="players-list">
              {players.map((player, index) => (
                <div key={index} className="player-card">
                  <div className="player-avatar">
                    {player.isHost ? '👑' : '👤'}
                  </div>
                  <div className="player-info">
                    <div className="player-name">{getPlayerName(player)}</div>
                    {player.rating && (
                      <div className="player-rating">Rating: {player.rating}</div>
                    )}
                    <div className={`player-status ${player.status === 'ready' ? 'ready' : 'not-ready'}`}>
                      {player.status === 'ready' ? 'Ready' : 'Not Ready'}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="room-info-section">
            <h2>Share this code with friends:</h2>
            <div className="room-code-container">
              <div className="room-code">{roomId}</div>
              <button className="copy-button" onClick={handleCopyCode}>
                {copySuccess ? '✓ Copied' : 'Copy Code'}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <GamePlayUI puzzle={puzzle} roomId={roomId} players={players} />
      )}

      <div className="game-room-footer">
        {gameStatus === 'waiting' && (
          <>
            <button 
              className={`ready-button ${isReady ? 'ready-active' : ''}`}
              onClick={handleToggleReady}
            >
              {isReady ? 'Not Ready?' : 'Ready?'}
            </button>
            <button 
              className={`leave-button ${isLeaving ? 'leaving' : ''}`}
              onClick={handleLeaveGame}
              disabled={isLeaving || isProcessingGameAction || !leaveButtonEnabled}
            >
              {isLeaving ? (
                <span className="dot-animation">Leaving Game</span>
              ) : (
                'Leave Game'
              )}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default GameRoomPage; 