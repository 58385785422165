import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGame } from '../contexts/GameContext';
import { useAuth } from '../contexts/AuthContext';
import './HomePage.css';

const HomePage = () => {
  const navigate = useNavigate();
  const { 
    createGame, 
    joinGame, 
    publicGames, 
    fetchPublicGames, 
    socket, 
    connected,
    checkActiveGame,
    returnToActiveGame,
    currentGame
  } = useGame();
  const { checkIsAuthenticated, playAsGuest, isGuest } = useAuth();
  const [roomCode, setRoomCode] = useState('');
  const [isPublic, setIsPublic] = useState(true);
  const [isRated, setIsRated] = useState(false);

  useEffect(() => {
    if (checkIsAuthenticated() && checkActiveGame()) {
      console.log('User has an active game:', currentGame?.roomId);
    }
  }, [checkIsAuthenticated, checkActiveGame, currentGame]);

  useEffect(() => {
    if (checkIsAuthenticated() && socket && connected) {
      socket.emit('get_public_games');
    }
  }, [checkIsAuthenticated, socket, connected]);

  const handleReturnToGame = () => {
    returnToActiveGame();
  };

  const handleCreateGame = () => {
    createGame({ isPublic, isRated });
  };

  const handleJoinGame = () => {
    if (roomCode.trim()) {
      joinGame(roomCode.trim());
    }
  };

  const handleJoinPublicGame = (roomId) => {
    joinGame(roomId);
  };

  const handleRefreshGames = () => {
    fetchPublicGames();
  };

  const handlePlayAsGuest = () => {
    playAsGuest();
  };

  const handleLogin = () => {
    navigate('/login');
  };

  if (!checkIsAuthenticated()) {
    return (
      <div className="home-container">
        <div className="home-header">
          <h1>Connections Racer</h1>
          <h2>Race to solve word connections puzzles!</h2>
        </div>

        {/* <div className="maintenance-banner warning">
          <span className="maintenance-banner-icon">⚠️</span>
          <span>This site is currently undergoing maintenance. Some features may be temporarily unavailable.</span>
        </div> */}

        <div className="auth-prompt">
          <h3>Please log in or play as a guest</h3>
          <div className="auth-buttons">
            <button onClick={handleLogin} className="btn-primary">
              Login
            </button>
            <button onClick={handlePlayAsGuest} className="btn-secondary">
              Play as Guest
            </button>
          </div>
        </div>

        <div className="how-to-play">
          <h2>How to Play</h2>
          <div className="steps-container">
            <div className="step-card">
              <div className="step-number">1</div>
              <h3>Find Connections</h3>
              <p>Group words into sets of four that share a common theme.</p>
            </div>
            <div className="step-card">
              <div className="step-number">2</div>
              <h3>Race Against Others</h3>
              <p>Solve puzzles faster than your opponents to win.</p>
            </div>
            <div className="step-card">
              <div className="step-number">3</div>
              <h3>Be Careful</h3>
              <p>You have limited lives. Each wrong guess costs one life.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="home-container">
      <div className="home-header">
        <h1>Connections Racer</h1>
        <h2>Race to solve word connections puzzles!</h2>
      </div>

      {/* <div className="maintenance-banner warning">
        <span className="maintenance-banner-icon">⚠️</span>
        <span>This site is currently undergoing maintenance. Some features may be temporarily unavailable.</span>
      </div> */}

      {checkActiveGame() && (
        <div className="active-game-banner">
          <p>You have an active game in progress</p>
          <button 
            className="return-game-button"
            onClick={handleReturnToGame}
          >
            Return to Game
          </button>
        </div>
      )}

      <div className="game-options-container">
        <div className="game-option-card">
          <h2>Create Game</h2>
          <div className="options-row">
            <label className="form-check">
              <input 
                type="checkbox"
                className="form-check-input"
                checked={isPublic} 
                onChange={(e) => setIsPublic(e.target.checked)}
              />
              <span className="form-check-label">Public Game</span>
            </label>
            
            {!isGuest && (
              <label className="form-check">
                <input 
                  type="checkbox"
                  className="form-check-input"
                  checked={isRated} 
                  onChange={(e) => setIsRated(e.target.checked)}
                />
                <span className="form-check-label">Rated Game</span>
              </label>
            )}
          </div>
          <button 
            className="primary-button"
            onClick={handleCreateGame}
          >
            Create Game
          </button>
        </div>

        <div className="game-option-card">
          <h2>Join Game</h2>
          <input
            type="text"
            placeholder="Enter Room Code"
            value={roomCode}
            onChange={(e) => setRoomCode(e.target.value)}
            className="room-code-input"
          />
          <button 
            className="primary-button"
            onClick={handleJoinGame}
          >
            Join Game
          </button>
        </div>
      </div>

      <div className="public-games-section">
        <h2>Open Public Games</h2>
        <div className="public-games-list">
          <button className="refresh-button" onClick={handleRefreshGames}>
            <span>↻</span> Refresh
          </button>
          
          {publicGames.length > 0 ? (
            <table className="public-games-table">
              <thead>
                <tr>
                  <th>Host</th>
                  <th>Players</th>
                  <th>Status</th>
                  <th>Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {publicGames.map(game => {
                  const host = game.players.find(p => p.isHost);
                  const hostName = host ? host.username : 'Unknown';
                  const gameType = game.guestOnly ? 'Guest Game' : 
                                  (game.isRated ? 'Rated' : 'Standard');
                  const maxPlayers = game.maxPlayers || 4;
                  const isFull = game.players.length >= maxPlayers;
                  const canJoin = isGuest ? game.guestOnly : true;
                  
                  return (
                    <tr key={game.roomId}>
                      <td>{hostName}</td>
                      <td>{game.players.length}/{maxPlayers}</td>
                      <td>{game.status === 'waiting' ? 'Waiting' : 'In Progress'}</td>
                      <td>{gameType}</td>
                      <td>
                        {game.status === 'waiting' && !isFull && canJoin && (
                          <button 
                            className="join-button"
                            onClick={() => handleJoinPublicGame(game.roomId)}
                          >
                            Join
                          </button>
                        )}
                        {game.status !== 'waiting' && (
                          <span className="game-status-text">In Progress</span>
                        )}
                        {isFull && (
                          <span className="game-status-text full">Full</span>
                        )}
                        {!canJoin && !isFull && (
                          <span className="game-status-text">Not Available</span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="no-games-message">
              No public games available. Create one to get started!
            </div>
          )}
        </div>
      </div>

      <div className="how-to-play">
        <h2>How to Play</h2>
        <div className="steps-container">
          <div className="step-card">
            <div className="step-number">1</div>
            <h3>Find Connections</h3>
            <p>Group words into sets of four that share a common theme.</p>
          </div>
          <div className="step-card">
            <div className="step-number">2</div>
            <h3>Race Against Others</h3>
            <p>Solve puzzles faster than your opponents to win.</p>
          </div>
          <div className="step-card">
            <div className="step-number">3</div>
            <h3>Be Careful</h3>
            <p>You have limited lives. Each wrong guess costs one life.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage; 