import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthContext = createContext();

axios.defaults.baseURL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [isGuest, setIsGuest] = useState(localStorage.getItem('isGuest') === 'true');
  const [guestId, setGuestId] = useState(localStorage.getItem('guestId'));
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(!!token || isGuest);
  const navigate = useNavigate();
  const [socket, setSocket] = useState(null);

  const checkIsAuthenticated = () => {
    return !!token || isGuest;
  };

  useEffect(() => {
    const loadUser = async () => {
      if (token) {
        try {
          const res = await axios.get('/api/auth/user', {
            headers: {
              'x-auth-token': token
            }
          });
          setUser(res.data);
        } catch (err) {
          console.error('Error loading user:');
          localStorage.removeItem('token');
          setToken(null);
          setUser(null);
        }
      }
      setLoading(false);
    };

    if (token) {
      loadUser();
    } else {
      setLoading(false);
    }
  }, [token]);

  const login = async (usernameOrEmail, password) => {
    try {
      const res = await axios.post('/api/auth/login', { usernameOrEmail, password });
      
      localStorage.setItem('token', res.data.token);
      setToken(res.data.token);
      setIsAuthenticated(true);
      
      return { success: true };
    } catch (err) {
      if (err.response && err.response.data) {
        if (err.response.data.requiresVerification) {
          return {
            success: false,
            requiresVerification: true,
            message: err.response.data.msg,
            email: err.response.data.email
          };
        }
        return { success: false, message: err.response.data.msg };
      }
      throw err;
    }
  };

  const register = async (usernameOrData, email, password) => {
    try {
      let userData;
      
      if (typeof usernameOrData === 'object') {
        userData = usernameOrData;
        if (userData.password) {
          userData.password = userData.password.trim();
        }
      } else {
        userData = {
          username: usernameOrData.trim(),
          email: email || '',
          password: password.trim()
        };
      }
      
      
      const res = await axios.post('/api/auth/register', userData);
      
      if (res.data && res.data.token && !res.data.requiresVerification) {
        localStorage.setItem('token', res.data.token);
        setToken(res.data.token);
        
        localStorage.removeItem('isGuest');
        localStorage.removeItem('guestId');
        setIsGuest(false);
        setGuestId(null);
      }
      
      return {
        success: true,
        emailSent: res.data.requiresVerification,
        message: res.data.message
      };
    } catch (err) {
      console.error('Register error:');
      return {
        success: false,
        message: err.response?.data?.message || 'Registration failed'
      };
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('isGuest');
    localStorage.removeItem('guestId');
    setToken(null);
    setUser(null);
    setIsGuest(false);
    setGuestId(null);
    navigate('/');
  };

  const playAsGuest = () => {
    const existingGuestId = localStorage.getItem('guestId');
    const newGuestId = existingGuestId || Math.random().toString(36).substring(2);
    
    if (!existingGuestId) {
      localStorage.setItem('guestId', newGuestId);
    }
    
    setGuestId(newGuestId);
    setIsGuest(true);
    setIsAuthenticated(true);
  };

  const exitGuestMode = () => {
    localStorage.removeItem('isGuest');
    localStorage.removeItem('guestId');
    setIsGuest(false);
    setGuestId(null);
    navigate('/login');
  };

  const fetchUserData = async () => {
    if (!token) return null;
    
    try {
      const res = await axios.get('/api/auth/user', {
        headers: {
          'x-auth-token': token
        }
      });
      
      setUser(res.data);
      return res.data;
    } catch (err) {
      console.error('Error fetching user data:');
      localStorage.removeItem('token');
      setToken(null);
      setUser(null);
      return null;
    }
  };

  useEffect(() => {
    const storedGuestId = localStorage.getItem('guestId');
    const storedToken = localStorage.getItem('token');
    
    if (storedToken) {
      setToken(storedToken);
      setIsAuthenticated(true);
      setIsGuest(false);
    } else if (storedGuestId) {
      setGuestId(storedGuestId);
      setIsGuest(true);
      setIsAuthenticated(true);
    }
  }, []);

  const ensureGuestId = () => {
    if (isGuest) {
      if (!guestId) {
        const newGuestId = `guest_${Date.now()}_${Math.random().toString(36).substring(2, 10)}`;
        setGuestId(newGuestId);
        localStorage.setItem('guestId', newGuestId);
      } else {
        localStorage.setItem('guestId', guestId);
      }
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      if (isGuest) {
        ensureGuestId();
      }
    };
    
    checkAuth();
  }, []);

  const loginWithGoogle = async () => {
    try {
      const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
      const googleAuthUrl = `${apiBaseUrl}/api/auth/google`;
      
      window.location.href = googleAuthUrl;
      
      return { success: true };
    } catch (err) {
      console.error('Google login error:');
      return {
        success: false,
        message: err.response?.data?.message || 'Google login failed'
      };
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = queryParams.get('token');
    
    window.history.replaceState({}, document.title, window.location.pathname);
    
    if (tokenFromUrl) {
      localStorage.setItem('token', tokenFromUrl);
      setToken(tokenFromUrl);
      setIsAuthenticated(true);
      setIsGuest(false);
      
      navigate('/');
    }
  }, [navigate]);

  const updateUsername = async (newUsername) => {
    try {
      const response = await axios.put(
        '/api/users/update-username',
        { newUsername },
        { headers: { 'x-auth-token': token } }
      );
      
      setUser(prev => ({...prev, username: newUsername}));
      
      return { success: true, message: response.data.message };
    } catch (err) {
      return { 
        success: false, 
        message: err.response?.data?.message || 'Failed to update username' 
      };
    }
  };

  useEffect(() => {
    import('socket.io-client').then(({ io }) => {
      const newSocket = io(process.env.REACT_APP_API_URL || 'http://localhost:5001');
      
      newSocket.on('connect', () => {
        setSocket(newSocket);
        
        const token = localStorage.getItem('token');
        if (token) {
          newSocket.emit('authenticate', { token });
        }
      });
      
      newSocket.on('forced_disconnect', (data) => {
        const confirmed = window.confirm(data.message + '\nClick OK to continue in this tab.');
        
        if (confirmed) {
          newSocket.emit('confirm_session', { confirmed: true });
        } else {
          logout();
        }
      });
      
      return () => {
        newSocket.disconnect();
      };
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        isGuest,
        guestId,
        loading,
        isAuthenticated,
        checkIsAuthenticated,
        login,
        register,
        logout,
        playAsGuest,
        exitGuestMode,
        loginWithGoogle,
        fetchUserData,
        updateUsername,
        socket
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}; 